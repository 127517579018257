import React, { useEffect, useState } from "react"
import styled from "styled-components"
import LineList from "../LineList"
import OverviewLine from "../lineItems/OverviewLine"
import Heading from "../../common/Heading"
import { Icons } from "../../common/style/Icons"
import { Colors } from "@flow/style"
import { Date as FlowDate } from "@flow/forms"
import {
  formatInputDate,
  mapCostOverview,
  mapTotalProjectCostAndBasisForSupportForCostOverview,
} from "../mapCaseInput"
import ActivityLine from "../lineItems/ActivityLine"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import InfoLine from "../InfoLine"
import TextButton from "../../common/TextButton"

const ActivitiesOverview = ({
  t,
  name,
  activityTypes,
  startDate,
  endDate,
  caseInput = {},
  activeActivity,
  productSummary,
  editCost,
  addCosts,
  removeCosts,
  resetCaseInput,
  createClone,
  currency,
  isDynamicCostBased,
}) => {
  const activityStart = formatInputDate(startDate)
  const activityEnd = formatInputDate(endDate)

  const costsOverview = mapCostOverview(caseInput)

  const sumActivities =
    mapTotalProjectCostAndBasisForSupportForCostOverview(costsOverview)

  const [isEditing, setEditing] = useState(false)

  const toggleEditMode = () => {
    if (!isEditing) {
      // If we start editing, create a clone of caseInput before editing started.
      createClone()
    }
    setEditing(!isEditing)
  }
  const revertChanges = () => {
    resetCaseInput()
    toggleEditMode()
  }

  return (
    <StyledActivity>
      <Header>
        <Heading>{name}</Heading>
        <DatesWrapper>
          <StyledDate
            tabIndex="-1"
            disabled
            name={"activityStart"}
            id={"startDate"}
            label={t("start-date")}
            onChange={(e) => e}
            value={activityStart}
          />
          <StyledDate
            tabIndex="-1"
            disabled
            name={"activityEnd"}
            label={t("end-date")}
            id={"endDate"}
            onChange={(e) => e}
            value={activityEnd}
          />
        </DatesWrapper>
      </Header>
      <p>{t("activity-type-heading").toUpperCase()}</p>
      {activityTypes?.length > 0 && (
        <StyledActivityType>
          {activityTypes.map((activity) => t(activity)).join(", ")}
        </StyledActivityType>
      )}
      <LineList>
        <OverviewLine
          product={t("product")}
          amount={t("amount")}
          type={t("type")}
          activity={t("activity")}
          subsidyRegime={t("subsidy-regime")}
          header={true}
        />
        {productSummary && Object.keys(productSummary).length > 0 ? (
          <>
            {Object.keys(productSummary)?.map((product, index) => {
              return (
                <>
                  <OverviewLine
                    icon={Icons[product]}
                    key={`${product}${index}`}
                    product={productSummary[product]?.productName}
                    amount={productSummary[product]?.amount}
                    type={productSummary[product]?.productType}
                    activityAndSubsidyRegime={
                      productSummary[product]?.activityAndSubsidyRegime
                    }
                    subproducts={productSummary[product]?.subproducts}
                    t={t}
                  />
                </>
              )
            })}
          </>
        ) : (
          <InfoLine text={t("product-not-chosen")} />
        )}
        <ActivityLine
          costType={t("cost-type")?.toUpperCase()}
          projectCost={t("project-cost")?.toUpperCase()}
          basisForSupport={t("support-basis")?.toUpperCase()}
          header={true}
          refreshValues={""}
        />

        {costsOverview?.map((cost, i) => {
          return (
            <ActivityLine
              costType={t(cost.name)}
              projectCost={cost.appliedFor}
              index={i}
              currency={currency}
              key={cost.variable}
              isCustomActivity={false}
              approved={cost.approved}
              readOnly={activeActivity}
              editCost={editCost}
              isEditing={isEditing}
              removeCosts={removeCosts}
            />
          )
        })}
        {isDynamicCostBased && isEditing && (
          <StyledAddButton>
            <TextButton
              onClick={() => addCosts()}
              padding={false}
              paddingConfiguration={undefined}
            >
              {`+ ${t("add")}`}
            </TextButton>
          </StyledAddButton>
        )}
        {activeActivity ? (
          <ActivityLine
            costType={t("sum-all-activities").toUpperCase()}
            projectCost={addThousandSeparator(sumActivities.projectCost)}
            basisForSupport={addThousandSeparator(
              sumActivities.basisForSupport
            )}
            header={true}
            currency={currency}
          />
        ) : (
          <ActivityLine
            costType={t("sum-all-activities").toUpperCase()}
            projectCost={addThousandSeparator(0)}
            basisForSupport={addThousandSeparator(caseInput?.inputTotal)}
            header={true}
            currency={currency}
          />
        )}
        {isDynamicCostBased && (
          <>
            {!isEditing && (
              <StyledButton>
                <TextButton
                  onClick={toggleEditMode}
                  padding={false}
                  paddingConfiguration={undefined}
                >
                  <Icons.Edit />
                </TextButton>
              </StyledButton>
            )}
            {isEditing && (
              <ButtonContainer>
                <TextButton
                  onClick={toggleEditMode}
                  padding={false}
                  paddingConfiguration={undefined}
                >
                  <Icons.Check color={Colors.GrassDark} />
                </TextButton>

                <TextButton
                  onClick={revertChanges}
                  padding={false}
                  paddingConfiguration={undefined}
                >
                  <Icons.Remove color={Colors.FerrariDark} />
                </TextButton>
              </ButtonContainer>
            )}
          </>
        )}
      </LineList>
    </StyledActivity>
  )
}
export default ActivitiesOverview

const StyledActivity = styled.div`
  padding: 20px;
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
`
const StyledActivityType = styled.div`
  background: ${Colors.GrassLighter};
  display: inline-block;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  pointer-events: none;
`
const StyledDate = styled(FlowDate)`
  color: inherit;
  padding: 0.15em;
  font-size: small;
  align-self: flex-end;
  line-height: 1em;
  margin-left: 0.3rem;
  background-color: inherit;
`
const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: inherit;
`
const StyledAddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: inherit;
  font-size: small;
  padding: 0.25em;
`
