/**
 * Copied from /setup/process/_common/constants/categories.js
 * Remember to keep these files in sync whenever a
 */
export const categoriesConstants = {
  EXTRAORDINARY_FINANCING: "extraordinaryfinancing",
  STARTUP: "startup",
  STARTUPMATIL: "startupmatil",
  COUNTER_GUARANTEE: "counterguarantee",
  AGRICULTURE: "agriculture",
  EXPORTPROMOTION: "exportpromotion",
  AGRICULTURE_ADDITIONAL_BUSINESS: "agricultureadditionalbusiness",
  BIOENERGY: "bioenergy",
  CLUSTER: "cluster",
  MAINTENANCE: "maintenance",
  DSF: "shippingandfishery",
  DNI: "businessdevelopmentandinnovation",
  HIP: "humanitarianinnovationproject",
}

/**
 * Shared function to map flow category to insight context.
 * @param {string} category The category of this flow
 * @param {boolean} isDecision If this is a "decision" context (basically means read-only versions of the cards)
 * @param {boolean | undefined} needAnalysis Determines if matil context should be used. If not undefined and false (needAnalysis === false), then context is matil. Relevant for specific cases like CreateCreditMemo/AssessDecision.
 * @param {boolean} isPdf If this is a pdf context
 * @returns {string} Insight context string
 */
export default function getInsightContextForCategory(
  category,
  isDecision = false,
  needAnalysis = undefined,
  isPdf = false
) {
  if (!category) {
    throw new Error(
      "getInsightContextForCategory() called without a category specified."
    )
  }

  if (!Object.values(categoriesConstants).includes(category)) {
    throw new Error(
      `getInsightContextForCategory() called with unknown category "${category}"`
    )
  }

  if (needAnalysis === false) {
    if (isDecision) {
      return "decisionMatil"
    }
    return "proposalMatil"
  }

  const categoryContextMap = {
    [categoriesConstants.STARTUP]: {
      proposal: "proposal",
      decision: "decision",
    },
    [categoriesConstants.EXTRAORDINARY_FINANCING]: {
      proposal: "proposalUtInntBortfall",
      decision: "decisionUtInntBortfall",
    },
    [categoriesConstants.COUNTER_GUARANTEE]: {
      proposal: "proposalGrowthGuarantee",
      decision: "decisionGrowthGuarantee",
    },
    [categoriesConstants.AGRICULTURE]: {
      proposal: "proposalAgriculture",
      decision: "agricultureDecision",
      decisionPdf: "decisionAgriculture",
    },
    [categoriesConstants.BIOENERGY]: {
      proposal: "proposalBioEnergy",
      decision: "bioEnergyDecision",
      decisionPdf: "decisionBioEnergy",
    },
    [categoriesConstants.EXPORTPROMOTION]: {
      proposal: "mass",
      decision: "massDecision",
    },
    [categoriesConstants.AGRICULTURE_ADDITIONAL_BUSINESS]: {
      proposal: "proposalDtil",
      decision: "dtilDecision",
      decisionPdf: "decisionDtil",
    },
    [categoriesConstants.MAINTENANCE]: {
      proposal: "proposalMaintenance",
      decision: "maintenanceDecision",
    },
    [categoriesConstants.DSF]: {
      proposal: "proposalDsf",
      decision: "dsfDecision",
    },
    [categoriesConstants.DNI]: {
      proposal: "proposalDni",
      decision: "dniDecision",
    },
    [categoriesConstants.HIP]: {
      proposal: "proposalHip",
      decision: "decisionHip",
    },
    [categoriesConstants.STARTUPMATIL]: {
      proposal: "proposalMatil",
      decision: "decisionMatil",
    },
  }

  const getContext = (category, isDecision, isPdf) => {
    if (!categoryContextMap[category]) {
      throw new Error(`Invalid category: ${category}`)
    }
    const { decision, proposal, decisionPdf } = categoryContextMap[category]
    if (isPdf && decisionPdf) {
      return decisionPdf
    }
    return isDecision ? decision : proposal
  }

  const context = getContext(category, isDecision, isPdf)

  return context
}
