const searchFieldsTapsvurdering = [
  {
    name: "tapsvurdering-status",
    value: "data.businessStatus",
    process: ["tapsvurdering"],
    type: "select",
    options: [
      {
        label: "Godkjent",
        value: "approved",
      },
      {
        label: "Under behandling",
        value: "assessment-in-progress",
      },
    ],
  },
  {
    name: "period",
    value: "data.input.year",
    process: ["tapsvurdering"],
  },
  {
    name: "customerId",
    value: "data.input.customer.customerId",
    process: ["tapsvurdering"],
  },
]

const searchResultFieldTapsvurdering = [
  {
    name: "customerId",
    value: "data.input.customer.customerId",
    process: ["tapsvurdering"],
  },
  {
    name: "name",
    value: "data.raw.accountMaster.customerAccount.name",
    process: ["tapsvurdering"],
  },
  {
    name: "status",
    value: "data.businessStatus",
    process: ["tapsvurdering"],
  },
  {
    name: "createdAt",
    value: "data.input.createdAt",
    process: ["tapsvurdering"],
  },
]

const searchFieldsVekstgaranti = [
  {
    name: "applying-bank",
    value: "data.application.applyingBank.name",
    queryOperator: "contains",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-type",
    value: "data.application.applyingCustomer.transactionType",
    queryOperator: "contains",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-amount-applied-for",
    value: "data.mapped.application.amountString",
    process: ["vekstgaranti"],
  },
  {
    name: "start-up-cost-and-guarantee-provision-total",
    value:
      "data.mapped.application.startUpCostAndGuaranteeProvisionTotalString",
    process: ["vekstgaranti"],
  },
  {
    name: "Status",
    value: "data.businessStatus",
    process: ["vekstgaranti"],
    type: "select",
    options: [
      {
        label: "Mottatt",
        value: "Mottatt",
      },
      {
        label: "Annullert",
        value: "Annullert",
      },
      {
        label: "Aktivert",
        value: "Aktivert",
      },
      {
        label: "Under behandling",
        value: "Under behandling",
      },
      {
        label: "Innvilget",
        value: "Innvilget",
      },
      {
        label: "Venter på søknad",
        value: "Venter på søknad",
      },
      {
        label: "Avslag",
        value: "Avslag",
      },
    ],
  },
]

const searchResultFieldVekstgaranti = [
  {
    name: "applying-bank",
    value: "data.application.applyingBank.name",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-type",
    value: "data.application.applyingCustomer.transactionType",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-amount-applied-for",
    value: "data.application.transactionDetails.amount",
    process: ["vekstgaranti"],
  },
  {
    name: "start-up-cost-and-guarantee-provision-total",
    value: "data.mapped.application.startUpCostAndGuaranteeProvisionTotal",
    process: ["vekstgaranti"],
  },
]

const getCategoriesForSearch = (type = "application") => {
  if (type === "maintenance") {
    return {
      MAINTENANCE: "maintenance",
    }
  }

  //Disse kategoriene tilhører "søknad-prosesen"
  //Vekstgaranti er ikke inkludert fordi det har en egen prosess
  return {
    EXTRAORDINARY_FINANCING: "extraordinaryfinancing",
    STARTUP: "startup",
    STARTUPMATIL: "startupmatil",
    AGRICULTURE: "agriculture",
    EXPORTPROMOTION: "exportpromotion",
    AGRICULTURE_ADDITIONAL_BUSINESS: "agricultureadditionalbusiness",
    BIOENERGY: "bioenergy",
    CLUSTER: "cluster",
    DSF: "shippingandfishery",
    DNI: "businessdevelopmentandinnovation",
    HIP: "humanitarianinnovationproject",
  }
}

const categoriesConstantsApplication = getCategoriesForSearch("application")
const categoriesConstantsMaintenance = getCategoriesForSearch("maintenance")
// Generate categories with names
const createLabelAndValue = (object) => {
  return Object.values(object).map((value) => ({
    label: value,
    value: value,
  }))
}
const categoriesApplication = createLabelAndValue(
  categoriesConstantsApplication
)
const categoriesMaintenance = createLabelAndValue(
  categoriesConstantsMaintenance
)

const searchFieldsApplication = [
  {
    name: "organization-number",
    value: "data.raw.accountMaster.customerAccount.publicId",
    process: ["application", "maintenance"],
  },
  {
    name: "application-name",
    value: "data.raw.applicationMaster.currentApplication.projectName",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-email",
    value: "data.raw.accountMaster.customerContactApplication.email",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-phone",
    value: "data.raw.accountMaster.customerContactApplication.mobilePhone",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "category",
    value: "data.application.category",
    type: "select",
    options: categoriesApplication,
    process: ["application"],
  },
  {
    name: "category-maintenance",
    value: "data.application.category",
    type: "select",
    options: categoriesMaintenance,
    process: ["maintenance"],
  },
  {
    name: "Søknadsstatus",
    value: "data.businessStatus",
    process: ["application", "maintenance"],
    type: "select",
    options: [
      {
        label: "Mottatt",
        value: "Mottatt",
      },
      {
        label: "Innledende behandling",
        value: "Innledende behandling",
      },
      {
        label: "Venter på kunde",
        value: "Venter på kunde",
      },
      {
        label: "Tidlig avslag",
        value: "Tidlig avslag",
      },
      {
        label: "Analyse",
        value: "Analyse",
      },
      {
        label: "Beslutning",
        value: "Beslutning",
      },
      {
        label: "Avslag",
        value: "Avslag",
      },
      {
        label: "Innvilget",
        value: "Innvilget",
      },
      {
        label: "Utbetaling",
        value: "Utbetaling",
      },
      {
        label: "Under behandling",
        value: "Under behandling",
      },
      {
        label: "Fullført",
        value: "Fullført",
      },
      {
        label: "Bortfalt",
        value: "Bortfalt",
      },
    ],
  },
]

const searchResultFieldsApplication = [
  {
    name: "application-name",
    value: "data.raw.applicationMaster.currentApplication.projectName",
    process: ["application", "maintenance"],
  },
  {
    name: "organization-number",
    value: "data.raw.accountMaster.customerAccount.publicId",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-email",
    value: "data.raw.accountMaster.customerContactApplication.email",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-phone",
    value: "data.raw.accountMaster.customerContactApplication.mobilePhone",
    process: ["application", "maintenance"],
  },
  {
    name: "category",
    value: "data.application.category",
    process: ["application"],
  },
  {
    name: "category-maintenance",
    value: "data.application.category",
    process: ["maintenance"],
  },
]

const searchResultFieldsPayoutControl = [
  {
    name: "payouts-from",
    value: "data.searchDate",
    process: ["payout-control"],
  },
]

export const searchFields = [
  ...searchFieldsVekstgaranti,
  ...searchFieldsApplication,
  ...searchFieldsTapsvurdering,
  {
    name: "applying-customer",
    value: "data.raw.accountMaster.customerAccount.name",
    queryOperator: "contains",
    process: ["vekstgaranti", "application", "maintenance"],
  },
]
export const searchResultFields = [
  {
    name: "applying-customer",
    value: "data.raw.accountMaster.customerAccount.name",
    process: ["application", "vekstgaranti", "maintenance"],
  },
  ...searchResultFieldVekstgaranti,
  ...searchResultFieldsApplication,
  ...searchResultFieldsPayoutControl,
  ...searchResultFieldTapsvurdering,
  {
    name: "created-at",
    value: "createdAt",
    process: ["application", "vekstgaranti", "payout-control", "maintenance"],
  },
  {
    name: "application-status",
    value: "data.businessStatus",
    process: ["application", "vekstgaranti", "maintenance"],
  },
]
