import React, { useEffect } from "react"
import styled from "styled-components"

import { useInsightApp } from "../../../hooks/InsightAppHook"
import isTesting from "../../util/isTesting"
import { useLanguage } from "../../../translate/useLanguage"

// readonly ut
const InsightModule = ({
  name,
  data,
  onAppChange,
  options = {},
  commonData,
}) => {
  const { locale } = useLanguage()
  const { externalId, orgNr, ssn } = commonData || {}
  const props = {
    name,
    data,
    externalId,
    orgNr,
    ssn,
    ...options,
    i18nLocale: locale,
  }
  console.debug("➡️INPUT PROPS", props)

  const app = useInsightApp(name, props)
  useEffect(() => {
    if (typeof onAppChange === "function") {
      onAppChange(app)
    }
  }, [app, onAppChange])

  if (isTesting()) return <div></div>

  const appId = `single-spa-application:${name}`

  return (
    <Container>
      <AppStyle id={appId}></AppStyle>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const AppStyle = styled.div`
  height: 100%;
`

export default InsightModule
