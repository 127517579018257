/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

const LossAssessmentSummary = ({
  customerAccount,
  t,
  year,
  caseId,
  parentFlowId,
}) => {
  return (
    <Content>
      <ItemWrapper>
        <Title>
          {customerAccount.accountType === "Company"
            ? t("company")
            : t("person")}
        </Title>
        <ContextContent>{customerAccount?.publicId}</ContextContent>
        <ContextContent>{customerAccount?.name}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("period")}</Title>
        <ContextContent>{year}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("caseId")}</Title>
        <ContextContent>{caseId}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("customer-contact-in")}</Title>
        <ContextContent>{customerAccount?.manager}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("urls")}</Title>
        <ContextContent>
          <a
            href={`/inbox/tapsvurdering-active-cases-tapsavsetning/tapsavsetning/${parentFlowId}`}
            target=""
            rel="noopener noreferrer"
          >
            {t("queue-tapsavsetning")}
          </a>
        </ContextContent>
      </ItemWrapper>
    </Content>
  )
}

const Content = styled.div`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(3, 1fr)"};
  gap: 10px;
`

const Title = styled.h3`
  font-weight: 300;

  ::first-letter {
    text-transform: capitalize;
  }

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default withTranslation()(LossAssessmentSummary)
