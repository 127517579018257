/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { formatDate } from "../utils/formatDate"

const YearlyLossAssessmentSummary = ({
  lossAssessments,
  t,
  csv,
  cancelled,
  year,
  createdAt,
}) => {
  const totalAmount = lossAssessments?.length ?? 0
  const approvedAmount = Object.keys(csv ?? {}).length
  const cancelledAmount = cancelled?.length ?? 0
  return (
    <Content>
      <ItemWrapper>
        <Title>{`${t("associated-loss-assessments")}`}</Title>
        <TwoGrid>
          <p>{`${t("Started")}:`}</p>
          <p>{totalAmount}</p>
        </TwoGrid>
        <TwoGrid>
          <p>{`${t("Approved")}:`}</p>
          <p>{approvedAmount}</p>
        </TwoGrid>
        <TwoGrid>
          <p>{`${t("Cancelled")}:`}</p>
          <p>{cancelledAmount}</p>
        </TwoGrid>

        <TwoGrid>
          <p>{`${t("remaining")}:`}</p>
          <p>{totalAmount - cancelledAmount - approvedAmount}</p>
        </TwoGrid>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("period")}</Title>
        <ContextContent>{year}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("created")}</Title>
        <ContextContent>{formatDate(createdAt)}</ContextContent>
      </ItemWrapper>
    </Content>
  )
}

const Content = styled.div`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(3, 1fr)"};
  gap: 10px;
`

const TwoGrid = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  padding: 5px;
`

const Title = styled.h3`
  font-weight: 300;

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default withTranslation()(YearlyLossAssessmentSummary)
