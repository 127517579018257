import { useState, useEffect } from "react"
import { debounce } from "lodash"

const useNaceLookup = () => {
  const [error, setError] = useState(null)
  const [industryValues, setIndustryValues] = useState([])
  const [query, setQuery] = useState("")

  // Consolidated fetch function using async/await syntax
  const fetchNaceData = async (searchString) => {
    return fetch(`/api/nace?searchText=${searchString}`)
      .then((response) => response.json())
      .then((res) => {
        const array = res?.map((item) => {
          return {
            label: `${item?.code} - ${item?.descriptionNorwegian}`,
            value: item,
          }
        })
        return array
      })
      .catch(function (e) {
        console.error("Error fetching NACE data", e)
        setError("search", {
          type: "manual",
          message: `nace-request-error-could-not-fetch`,
        })
      })
  }
  /**
   * @typedef {Object} NaceObject
   * @property {string} code - NACE code
   * @property {string} descriptionNorwegian - Norwegian description
   * @property {string} descriptionEnglish - English description
   *
   * @typedef {Object} NaceOption
   * @property {string} label - Label for the option
   * @property {NaceObject} value - Value for the option
   *
   */

  /**
   * Debounced fetch function to limit number of requests
   * @param {string} value
   * @returns {Promise<NaceOption[]>} - Array of NaceOptions
   */
  const queryFunc = (value) => {
    //add delay to limit number of requests
    return new Promise((resolve) => setTimeout(resolve, 500)).then(() => {
      return fetchNaceData(value)
    })
  }

  // Debounce fetchNaceData instead of defining a separate function
  const debouncedFetchNaceData = debounce(fetchNaceData, 500)

  useEffect(() => {
    if (query) debouncedFetchNaceData(query)
  }, [query, debouncedFetchNaceData])

  return {
    industryValues,
    setIndustryValues,
    setQuery, // Used to trigger debounced search
    error,
    naceLookup: queryFunc, // The hook insight can use
  }
}

export default useNaceLookup
