import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { Icons } from "@flow/icons"
import TextButton from "../common/TextButton"
import IconButton from "../common/IconButton"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import { Checkbox, Select, Text } from "@flow/forms"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import axios from "axios"
import { debounce } from "lodash"
import { v4 as uuidv4 } from "uuid"
import InsightNote from "../InsightNote"

/*
interface ProjectParticipant {
  id: string;
  isForeignCompany: boolean;
  companyName: string;
  organizationNumber?: string;
  countryOfOrigin?: string;
  staffCosts: number;
  otherCosts: number;
  role: string | null;
}
*/

const ParticipantRoles = {
  PILOTCUSTOMER: "pilotcustomer",
  FINANCING: "financing",
  COMPETANCE: "competance",
  COMPETANCE_AND_FINANCING: "competanceAndFinancing",
  PRODUCT_OR_SOLUTION: "productOrSolution",
}

function ProjectParticipant({
  t,
  participant,
  editingId,
  onEdit,
  onDelete,
  onSave,
  onCancel,
}) {
  const isEditingThisParticipant = editingId === participant.id

  const [companyName, setCompanyName] = useState(participant.companyName ?? "")
  const [organizationNumber, setOrganizationNumber] = useState(
    participant.organizationNumber ?? ""
  )
  const [isForeignCompany, setIsForeignCompany] = useState(
    participant.isForeignCompany ?? false
  )
  const [countryOfOrigin, setCountryOfOrigin] = useState(
    participant.countryOfOrigin ?? ""
  )
  const [staffCosts, setStaffCosts] = useState(
    Number(participant.staffCosts ?? 0)
  )
  const [otherCosts, setOtherCosts] = useState(
    Number(participant.otherCosts ?? 0)
  )
  const [role, setRole] = useState(participant.role ?? null)

  const updateCompanyNameFromOrganizationNumber = async (orgNumber) => {
    try {
      const { data } = await axios.get(
        `/api/organizationName?orgNumber=${orgNumber}`
      )
      if (data && data.length > 0) {
        setCompanyName(data)
      }
    } catch {
      // Handle errors if needed
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunction = useCallback(
    debounce((orgNumber) => {
      updateCompanyNameFromOrganizationNumber(orgNumber)
    }, 1000),
    [] // Dependencies array can be empty as the function dependencies are static
  )

  // Cleanup / cancel lookup if unmounting
  useEffect(() => {
    return () => {
      debouncedFunction.cancel()
    }
  }, [debouncedFunction])

  if (isEditingThisParticipant) {
    const numberOnChange = (setter) => {
      return (e) => {
        const newValue = e.target.value.replaceAll(" ", "")
        if (newValue.length === 0 || /^\d+$/.test(newValue)) {
          setter(Number(newValue))
        }
      }
    }

    const onOrganizationNumberChange = (e) => {
      setOrganizationNumber(e.target.value)
      debouncedFunction(e.target.value)
    }

    const roleOptions = Object.values(ParticipantRoles).map((value) => ({
      value,
      label: t(value),
    }))

    return (
      <ProjectParticipantContainerEditing>
        <ParticipantField>
          <p>{t("project-participants-field-companyName")}</p>
          <StyledTextInput
            id="companyName"
            name="companyName"
            onChange={(e) => setCompanyName(e.target.value)}
            value={companyName}
          />
        </ParticipantField>
        <ParticipantField>
          <p>{t("project-participants-field-organizationNumber")}</p>
          <StyledTextInput
            id="organizationNumber"
            name="organizationNumber"
            disabled={isForeignCompany}
            onChange={onOrganizationNumberChange}
            value={organizationNumber}
          />
        </ParticipantField>
        <ParticipantField>
          <Checkbox
            id="isForeignCompany"
            label={t("project-participants-field-isForeignCompany")}
            name="isForeignCompany"
            onChange={(_e, checked) => setIsForeignCompany(checked)}
            defaultChecked={isForeignCompany}
          />
        </ParticipantField>
        <ParticipantField>
          {isForeignCompany && (
            <>
              <p>{t("project-participants-field-countryOfOrigin")}</p>
              <StyledTextInput
                id="countryOfOrigin"
                name="countryOfOrigin"
                onChange={(e) => setCountryOfOrigin(e.target.value)}
                value={countryOfOrigin}
              />
            </>
          )}
        </ParticipantField>
        <ParticipantField>
          <p>{t("project-participants-field-staffCosts")}</p>
          <StyledTextInput
            id="staffCosts"
            name="staffCosts"
            onChange={numberOnChange(setStaffCosts)}
            value={addThousandSeparator(staffCosts)}
          />
        </ParticipantField>
        <ParticipantField>
          <p>{t("project-participants-field-otherCosts")}</p>
          <StyledTextInput
            id="otherCosts"
            name="otherCosts"
            onChange={numberOnChange(setOtherCosts)}
            value={addThousandSeparator(otherCosts)}
          />
        </ParticipantField>
        <ParticipantField>
          <p>{t("project-participants-field-role")}</p>
          <StyledSelect
            showBlankOption
            blankOptionLabel={t("choose")}
            id="role"
            name="role"
            value={role}
            options={roleOptions}
            onChange={(e) => setRole(e.target.value)}
          />
        </ParticipantField>
        <ParticipantField />
        <ParticipantField>
          <ParticipantEditingActions>
            <PrimaryButton
              disabled={
                // Company name is always required
                !companyName ||
                // If it is a foreign company, countryOfOrigin is required
                (isForeignCompany && !countryOfOrigin) ||
                // If it is a domestic company, organizationNumber is required
                (!isForeignCompany && !organizationNumber) ||
                // Role is always required
                !role
              }
              onClick={() =>
                onSave({
                  id: participant.id,
                  companyName,
                  organizationNumber: isForeignCompany
                    ? ""
                    : organizationNumber,
                  isForeignCompany,
                  countryOfOrigin: isForeignCompany ? countryOfOrigin : "",
                  staffCosts,
                  otherCosts,
                  role,
                  // Inline hack to only put isNew property there if it exists already
                  ...(participant.isNew ? { isNew: true } : {}),
                })
              }
            >
              {t("project-participants-save")}
            </PrimaryButton>
            <SecondaryButton onClick={onCancel}>
              {t("project-participants-cancel")}
            </SecondaryButton>
          </ParticipantEditingActions>
        </ParticipantField>
      </ProjectParticipantContainerEditing>
    )
  }

  return (
    <ProjectParticipantContainer>
      <Icons.StakeholderOrganization size={36} />
      <ParticipantField>
        <p>{t("project-participants-field-companyName")}</p>
        <ParticipantFieldValue>{participant.companyName}</ParticipantFieldValue>
      </ParticipantField>
      <ParticipantField>
        <p>
          {isForeignCompany
            ? t("project-participants-field-countryOfOrigin")
            : t("project-participants-field-organizationNumber")}
        </p>
        <ParticipantFieldValue>
          {isForeignCompany
            ? participant.countryOfOrigin
            : participant.organizationNumber}
        </ParticipantFieldValue>
      </ParticipantField>
      <ParticipantField>
        <p>{t("project-participants-field-staffCosts")}</p>
        <ParticipantFieldValue>
          {addThousandSeparator(participant.staffCosts)} NOK
        </ParticipantFieldValue>
      </ParticipantField>
      <ParticipantField>
        <p>{t("project-participants-field-otherCosts")}</p>
        <ParticipantFieldValue>
          {addThousandSeparator(participant.otherCosts)} NOK
        </ParticipantFieldValue>
      </ParticipantField>
      <ParticipantField>
        <p>{t("project-participants-field-role")}</p>
        <ParticipantFieldValue>
          {t(participant.role ?? "not-chosen")}
        </ParticipantFieldValue>
      </ParticipantField>
      <ParticipantActions>
        <IconButton disabled={!!editingId} icon="Edit" onClick={onEdit} />
        <IconButton disabled={!!editingId} icon="Trashcan" onClick={onDelete} />
      </ParticipantActions>
    </ProjectParticipantContainer>
  )
}

export default function ProjectParticipants({
  t,
  participants,
  setParticipants,
  insightComponentData,
}) {
  const [editingId, setEditingId] = useState(
    participants.find((p) => p.isNew)?.id ?? undefined
  )

  const { commonData } = insightComponentData

  const createNewParticipant = () => {
    const newId = uuidv4()
    setParticipants((old) => [
      ...old,
      {
        id: newId,
        organizationNumber: "",
        companyName: "",
        isForeignCompany: false,
        countryOfOrigin: "",
        staffCosts: 0,
        otherCosts: 0,
        role: null,
        isNew: true,
      },
    ])
    setEditingId(newId)
  }
  const deleteParticipantWithId = (id) =>
    setParticipants((old) => old.filter((p) => p.id !== id))

  return (
    <ContentWrapper>
      <TitleAndAddButton>
        <h2>{t("project-participants")}</h2>
        <TextButton disabled={!!editingId} onClick={createNewParticipant}>
          {t("project-participants-add")}
        </TextButton>
      </TitleAndAddButton>
      <ContentContainer>
        {participants.map((participant) => (
          <ProjectParticipant
            key={participant.id}
            t={t}
            participant={participant}
            editingId={editingId}
            onEdit={() => setEditingId(participant.id)}
            onDelete={() => deleteParticipantWithId(participant.id)}
            onCancel={() => {
              if (participant.isNew) {
                deleteParticipantWithId(editingId)
              }
              setEditingId(undefined)
            }}
            onSave={(newParticipant) => {
              if (newParticipant.isNew) {
                delete newParticipant.isNew
              }
              setParticipants((old) =>
                old.map((p) => (p.id === participant.id ? newParticipant : p))
              )
              setEditingId(undefined)
            }}
          />
        ))}
      </ContentContainer>
      <InsightNote
        variant="footer"
        context="projectParticipants"
        commonData={commonData}
      />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.Grey1};
  margin: 1.5rem;
  gap: 1rem;
`

const TitleAndAddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 1rem;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ProjectParticipantContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr repeat(5, 1fr) 0.25fr;
  padding: 2rem;
  background-color: ${Colors.SeaLighter};
  border-radius: 1rem;
`

const ProjectParticipantContainerEditing = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 0.5fr;
  gap: 1rem;
  padding: 2rem;
  background-color: ${Colors.SeaLighter};
  border-radius: 1rem;
  border: 1px solid ${Colors.Sea};
`

const ParticipantField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const ParticipantFieldValue = styled.p`
  color: ${Colors.Coal};
`

const ParticipantActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
`

const StyledTextInput = styled(Text)`
  max-width: 20rem;
`

// I have no idea why, but setting select max width to 20rem (consistent with above)
// results in a 1 rem difference in width. Setting max-width to 21rem for the select
// makes them both 20rem. Love CSS <3
const StyledSelect = styled(Select)`
  max-width: 21rem;
`

const ParticipantEditingActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
`
