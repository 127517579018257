import { withTranslation } from "react-i18next"
import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Layout from "../components/common/Layout"
import formatWithLocal from "../util/dateFormatter"
import { addThousandSeparator } from "../util/addThousandSeparator"
import PayoutGrid from "../components/payoutControl/PayoutGrid"
import { Colors } from "@flow/style"

/**
 * Sorts payments by date in ascending order (oldest first)
 * @param {{ payoutDate: Date }} a - First date
 * @param {{ payoutDate: Date }} b - Second date
 * @returns {number}
 */
const sortByDate = (a, b) => {
  if (a.payoutDate < b.payoutDate) return -1
  if (a.payoutDate > b.payoutDate) return 1
  return 0
}

/** @typedef {{ data: string}} DisplayElement */

/**
 * Gets and maps the display lists, supporting both the old and new data formats.
 * @param {object} data - `flow.data`
 * @param {"nornett" | "konsern"} identifier - Which payment list to get
 * @returns {DisplayElement[]}
 */
function getDisplayList(data, identifier) {
  const payouts = data.payouts?.[identifier] ?? []

  return payouts.sort(sortByDate).map((payout) => {
    //
    // Simple heuristic to see if this payout is using the old format.
    //
    if (payout.makePayout) {
      return [
        {
          data: payout.commitment.accountByAccountName.accountName,
        },
        {
          data: payout.makePayout.creditAccountName,
        },
        {
          data: formatWithLocal(payout.payoutDate.Date, "dd.MM.yyyy"),
        },
        {
          data: payout.payoutRequestReceived.content.customerName,
        },
        {
          data: addThousandSeparator(payout.makePayout.amount),
        },
        {
          data: payout.payoutRequestReceived.content.referenceNumber,
        },
        {
          data: payout.user.name,
        },
        {
          data: formatWithLocal(payout.decisionDate, "dd.MM.yyyy"),
        },
      ]
    }

    // The new format is much simpler:
    // {
    //   "accountName": string,
    //   "creditAccountName": string,
    //   "customerName": string,
    //   "referenceNumber": string,
    //   "decisionDate": string,
    //   "user": string,
    //   "flowId": string,
    //   "payoutDate": string,
    //   "deliveryId": string,
    //   "payoutAmount": number
    // }

    return [
      { data: payout.accountName },
      { data: payout.creditAccountName },
      { data: formatWithLocal(payout.payoutDate, "dd.MM.yyyy") },
      { data: payout.customerName },
      { data: addThousandSeparator(payout.payoutAmount) },
      { data: payout.referenceNumber },
      { data: payout.user },
      { data: formatWithLocal(payout.decisionDate, "dd.MM.yyyy") },
    ]
  })
}

const VerifyPayouts = ({ task, flow, t, schema, save, complete }) => {
  const [formData, setFormdata] = useState(task.data)
  const [isProcessing, setProcessing] = useState(false)

  const nornettDisplayList = getDisplayList(flow.data, "nornett")
  const konsernDisplayList = getDisplayList(flow.data, "konsern")

  const titles = [
    "Kontonr i coreView",
    "Kontonr for utbetaling",
    "Utført utbetaling",
    "Mottakers navn",
    "Beløp",
    "Saksnummer",
    "Utført av",
    "Tilsagnsdato",
  ]

  const handleComplete = () => {
    setProcessing(false)
    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContent>
        <PayoutGrid
          titles={titles}
          payouts={nornettDisplayList}
          t={t}
          color={Colors.OrangeLighter}
          headline="Nornett"
        />
        <PayoutGrid
          titles={titles}
          payouts={konsernDisplayList}
          t={t}
          color={Colors.GrassLighter}
          headline="Konsern"
        />
      </MainContent>
      <Context flow={flow} context={task.contexts}>
        <ReactForm
          schema={schema}
          formData={formData}
          onChange={(values) => setFormdata(values)}
        >
          <></>
        </ReactForm>

        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            onClick={() => handleComplete()}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  overflow: auto;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

export default withTranslation()(VerifyPayouts)
