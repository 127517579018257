import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import React, { useState } from "react"
import Heading from "../components/common/Heading"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { Colors } from "@flow/style"
import DownloadFileButton from "../components/common/DownloadFileButton"
import { MiniTable } from "../components/controlPriority/TableForCheckList"
import { FormProvider, useForm } from "react-hook-form"
import InsightModule from "../components/insight/InsightModule"
import { formatDate } from "../components/utils/formatDate"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../components/utils/mapAgreements"

const RegisterCollateralMovablesManual = ({
  task,
  t,
  flow,
  schema,
  save,
  complete,
  user,
}) => {
  const { variables } = task
  const [taskData, setTaskData] = useState(task.data || task.default)
  const [isProcessing, setProcessing] = useState(false)
  const currentAgreementId = variables?.collateralElement
  const delivery = flow?.data?.deliveries[variables.delivery.id]
  const tinglysning =
    delivery?.collateral?.servicesEtinglysing[currentAgreementId]
      ?.registrationStatus

  const defaultValue = {
    [currentAgreementId]: {
      checklistComment: taskData?.checklistComment ?? "",
      checklistControlled: taskData?.checklistControlled ?? false,
      checklistControlledBy: taskData?.checklistControlledBy ?? "",
    },
  }

  const engagements = flow.data.mapped.engagement.loans.map((loan) => {
    return {
      ...loan,
      riskGroupCode:
        task.data?.loans?.find(
          (taskLoan) => loan.accountNumber === taskLoan.accountNumber
        )?.riskGroupCode ?? loan.riskGroupCode,
    }
  })
  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )
  const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
    flow.data.mapped.agreementData || [],
    newAndExistingCollateralEngagements,
    engagements,
    securityRiskData
  )

  const methods = useForm({
    defaultValues: defaultValue,
  })
  const currentUser = user?.profile?.name

  const handleSave = (values) => {
    const formData = methods.getValues()
    const newData = { ...taskData, ...formData[currentAgreementId] }
    setProcessing(true)
    save(
      newData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    const formData = methods.getValues()
    const newData = { ...taskData, ...formData[currentAgreementId] }
    setProcessing(true)
    complete(
      newData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const movablesDocumentTypes = [
    "collateralAgricultureMovables",
    "collateralAgricultureDriftstilbehor",
    "collateralAgricultureVarelager",
    "collateralAgricultureFactoring",
    "collateralAgricultureMotorvognOgAnleggsmaskiner",
  ]

  const relevantDocuments = flow.data.files.filter(
    (x) =>
      x.documentGroup === "Archive" &&
      movablesDocumentTypes.includes(x.documentType)
  )

  return (
    <Layout forceHeight>
      <MainContainer>
        <FormProvider {...methods}>
          <form>
            <StyledWarningInfoBox>
              <p>{t("tinglysning-cancelled")}</p>
              <p>{t("tinglysning-cancelled-2")}</p>
            </StyledWarningInfoBox>
            <InsightComponentStyle>
              <InsightModule
                name={"@stacc/vue-corporateanalysis"}
                commonData={""}
                data={{
                  agreementData: mappedLoansWithCollateralAgreements,
                }}
                options={{
                  context: "agreementregistersummary",
                }}
              />
            </InsightComponentStyle>
            {tinglysning && (
              <StyledWarningInfoBox>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p>{t("status")}: </p>
                  <p style={{ textTransform: "uppercase", paddingLeft: 4 }}>
                    {tinglysning.status}
                  </p>
                </div>
                <div>
                  <p>{t("rejection-reason")}: </p>
                  <p>{tinglysning.avslagsgrunn}</p>
                </div>
                <div>
                  <p>{t("diary-date")}: </p>
                  <p>
                    {tinglysning.tinglysingsdato.includes("T")
                      ? formatDate(
                          tinglysning.tinglysingsdato,
                          "dd.MM.yyyy HH.mm.ss"
                        )
                      : tinglysning.tinglysingsdato}
                  </p>
                </div>
              </StyledWarningInfoBox>
            )}
            <Content>
              <p>
                {t("emptyAgricultureLøsøre")}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.brreg.no/wp-content/uploads/GA-4369B.doc"
                >
                  her
                </a>
              </p>
            </Content>
            <div>
              <Heading>{t("relevant-documents")}</Heading>
              <Row>
                {relevantDocuments
                  .filter((x) => !!x)
                  .map((x, i) => (
                    <DownloadFileButton
                      title={t(x?.filename)}
                      fileId={x?.storeID}
                      key={i}
                    />
                  ))}
              </Row>
            </div>
            <StyledMovables>
              <StyledTable>
                <MiniTable
                  t={t}
                  agreementId={currentAgreementId}
                  currentUser={currentUser}
                />
              </StyledTable>
            </StyledMovables>
          </form>
        </FormProvider>
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 100%;
  overflow-y: auto;
  gap: 1rem;
`
const StyledWarningInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: ${Colors.OrangeLighter};
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1em;
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  display: flex;
  padding: 10px;
  background-color: ${Colors.SeaLighter};
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 1em;
`

const StyledMovables = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
  border-radius: 15px;
  padding-top: 1em;
  margin-top: 1em;
`
const StyledTable = styled.div`
  display: flex;
  width: 100%;
`

const InsightComponentStyle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1em;
`

export default withTranslation()(RegisterCollateralMovablesManual)
