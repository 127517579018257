import React, { useCallback, useEffect, useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import RequestedDocArchive from "../components/common/RequestedDocArchive"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import {
  validateInput,
  validateOutput,
} from "../components/insight/validateSchema"
import { returnFirstArgWithValue } from "../util/returnValue"
import { Colors } from "@flow/style"
import InsightModule from "../components/insight/InsightModule"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Tabs from "../components/common/Tabs"
import Notice from "../components/common/Notice"
import AssessmentComments from "../components/common/AssessmentComments"
import BlueDot from "../components/common/BlueDot"
import lodash from "lodash"
import InternalInterestCalculation from "../components/AnalyzeApplication/InternalInterestCalculation"
import { FLOW_NS_DEV_OR_TEST_OR_STAGING } from "../components/ONLY_RENDER_IN_DEVELOPMENT"
const AnalyzeApplication = (props) => {
  const { task, t, schema, flow } = props
  const { data, defaults } = task
  const {
    applicationSummary,
    insightComponentData,
    specialConditions,
    isAgriculture,
    isBioEnergy,
    isDtil,
    isMaintenance,
    isDsf,
    isDni,
  } = task?.context || {}

  const { financePlan, commonData, internalInterest, enkData } =
    insightComponentData || {}

  const applicationContent = flow.data?.application?.applicationContent
  const financePlanData = flow.data?.mapped?.financePlan
  const stateData = {
    ...(flow.data?.analysis?.analyzeApplication || {}),
    deliveries: specialConditions || {},
  }
  delete stateData.setDecisionBasis
  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data?.formData, stateData, defaults) || {}
  )
  const [specialTerms, setSpecialTerms] = useState(formData.deliveries)
  const [activeApp, setActiveApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(activeApp)
  const [analysisTemplate, setAnalysisTemplate] = useState({})

  const [isProcessing, setProcessing] = useState(false)
  const [activeTab, setActiveTab] = useState("accounting")

  const [errors, setErrors] = useState([])

  const setModuleState = (financePlan, moduleState) => {
    return {
      actualSelfFinancing: 0,
      actualOwnerAndInvestorFinancing: 0,
      actualPublicFinancing: 0,
      actualOtherFinancing: 0,
      actualOwnWork: 0,
      ...financePlan,
      ...moduleState,
    }
  }

  const setInternalInterestState = (internalInterest, internalState) => {
    return {
      ...internalInterest,
      ...internalState,
    }
  }
  const setEnkDataState = (enkData, enkState) => {
    return {
      ...enkData,
      ...enkState,
    }
  }

  const [insightModuleState, setInsightModuleState] = useState(
    setModuleState(financePlan, data?.financePlan)
  )
  const [insightInternalInterestState, setInsightInternalInterestState] =
    useState(setInternalInterestState(internalInterest, data?.internalInterest))

  const createDefaultInternalInterestData = () => {
    const internalInterestRateData =
      applicationContent?.external?.internalRateOfReturn?.data?.data?.input
    return {
      fuelCostCentPerKwh: {
        fromApplication: internalInterestRateData?.fuelCostCentPerKwh,
        actualValue: internalInterestRateData?.fuelCostCentPerKwh,
        category: "costs",
      },
      maintenanceCostsCentPerKwh: {
        fromApplication: internalInterestRateData?.maintenanceCostsCentPerKwh,
        actualValue: internalInterestRateData?.maintenanceCostsCentPerKwh,
        category: "costs",
      },
      operatingCostsCentPerKwh: {
        fromApplication: internalInterestRateData?.operatingCostsCentPerKwh,
        actualValue: internalInterestRateData?.operatingCostsCentPerKwh,
        category: "costs",
      },
      efficiencyPercentage: {
        fromApplication: 85,
        actualValue: 85,
        category: "costs",
      },
      expectedProductionKwhPerYear: {
        fromApplication: internalInterestRateData?.expectedProductionKwhPerYear,
        actualValue: internalInterestRateData?.expectedProductionKwhPerYear,
        category: "revenues",
      },
      salesPriceCentPerKwh: {
        fromApplication: internalInterestRateData?.salesPriceCentPerKwh,
        actualValue: internalInterestRateData?.salesPriceCentPerKwh,
        category: "revenues",
      },
      otherRevenuePerYear: {
        fromApplication: internalInterestRateData?.otherRevenuePerYear,
        actualValue: internalInterestRateData?.otherRevenuePerYear,
        category: "revenues",
      },
      investmentKr: {
        fromApplication: internalInterestRateData?.investmentKr ?? 0,
        actualValue: internalInterestRateData?.investmentKr ?? 0,
        category: "investment",
      },
      grantsKr: {
        fromApplication: financePlanData?.appliedGrant,
        actualValue: financePlanData?.actualGrant,
        category: "investment",
        readOnly: true,
      },
      lifetimeYear: {
        fromApplication: internalInterestRateData?.lifetimeYear,
        actualValue: internalInterestRateData?.lifetimeYear,
        category: "investment",
      },
    }
  }

  const getDefaultInternalInterestData = () => {
    let dataToUse = data?.internalInterestData
    // If task data is empty, use state data instead
    if (lodash.isEmpty(data?.internalInterestData)) {
      // If state data is empty, use default data
      dataToUse = lodash.isEmpty(stateData.internalInterestData)
        ? createDefaultInternalInterestData()
        : stateData.internalInterestData
    }

    // This one needs to be calculated to whatever is on the state
    dataToUse.grantsKr.fromApplication = financePlanData?.appliedGrant
    dataToUse.grantsKr.actualValue = financePlanData?.actualGrant

    return dataToUse
  }

  const [internalInterestData, setInternalInterestData] = useState(
    getDefaultInternalInterestData()
  )

  const [insightEnkDataState, setInsightEnkDataState] = useState(
    setEnkDataState(enkData, data?.enkData)
  )

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = () => {
    const data = activeApp?.getCurrentState()
    const financePlan = {
      ...insightModuleState,
      ...data?.financePlan,
    }
    const internalInterest = {
      ...insightInternalInterestState,
      ...data?.internalInterest,
    }
    const enkData = {
      ...insightEnkDataState,
      ...data?.enkBudget,
    }
    setProcessing(true)
    setSpecialTerms((currentSpecialTerms) => {
      const toSave = {
        formData: { ...formData, deliveries: currentSpecialTerms },
        financePlan,
        internalInterest,
        internalInterestData,
        enkData,
      }
      props.save(
        toSave,
        () => setProcessing(false),
        () => {
          console.error("Could not save task")
          setProcessing(false)
        }
      )

      // Returning the current value to avoid updating the state unintentionally
      return currentSpecialTerms
    })
  }
  const handleComplete = (values) => {
    const data = activeApp?.getCurrentState()
    const financePlan = {
      ...insightModuleState,
      ...data?.financePlan,
    }
    const internalInterest = {
      ...insightInternalInterestState,
      ...data?.internalInterest,
    }

    const { calculatedInterestBeforeGrant, calculatedInterestWithGrant } =
      internalInterest

    if (
      isBioEnergy &&
      (!calculatedInterestBeforeGrant ||
        !calculatedInterestWithGrant ||
        calculatedInterestBeforeGrant === 0 ||
        calculatedInterestWithGrant === 0 ||
        typeof calculatedInterestBeforeGrant !== "number" ||
        typeof calculatedInterestWithGrant !== "number")
    ) {
      setErrors((prev) => {
        if (prev.includes("invalidInternalInterest")) {
          return prev
        }

        return [...prev, "invalidInternalInterest"]
      })
      return
    } else {
      setErrors((prev) => prev.filter((x) => x !== "invalidInternalInterest"))
    }

    const enkData = {
      ...insightEnkDataState,
      ...data?.enkBudget,
    }

    setProcessing(true)
    props.complete(
      {
        ...formData,
        deliveries: specialTerms,
        financePlan,
        internalInterest,
        internalInterestData,
        enkData,
      },
      () => {
        setProcessing(false)
      },
      (errors) => {
        setProcessing(false)
        const validationErrors = errors?.response?.data?.errors || []
        setErrors(validationErrors.map((x) => x.message))
        console.error("Could not complete task", validationErrors)
      }
    )
  }

  useEffect(() => {
    validateInput(
      {
        financePlan: insightModuleState,
        commonData,
      },
      "@stacc/vue-corporateanalysis"
    )
  }, [])

  useEffect(() => {
    validateOutput(
      { financePlan: insightModuleState },
      "@stacc/vue-corporateanalysis"
    )
  }, [insightModuleState])

  const onAppChange = useCallback(
    (app) => {
      // const data = app?.getCurrentState()
      //setInsightModuleState({ ...insightModuleState, ...data?.financePlan })
      setActiveApp(app)
    },
    [setActiveApp]
  )

  const isTaxonomyRelevant =
    flow.data.analysis?.setDecision?.isTaxonomyRelevant ?? false

  const getAnalyzeTab = (id, title, context) => {
    let hasSettingsForAgriculture = isAgriculture
    if (context === "financeplan" && (isAgriculture || isBioEnergy || isDtil))
      hasSettingsForAgriculture = true
    return {
      id,
      title,
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          data={{
            commonData,
            financePlan: insightModuleState,
            isAgriculture: hasSettingsForAgriculture,
            internalInterest: insightInternalInterestState,
            enkData: insightEnkDataState,
            showTaxonomy: isTaxonomyRelevant,
            analysisTemplate: analysisTemplate,
          }}
          onAppChange={onAppChange}
          options={{
            context: context,
          }}
          commonData={commonData}
        />
      ),
    }
  }

  const decisionCount = flow?.data?.assessmentComments?.length ?? 0

  const updateSpecialTerms = ({ deliveryId, data }) => {
    setSpecialTerms((oldSpecialTerms) => {
      const cloned = lodash.cloneDeep(oldSpecialTerms)
      cloned[deliveryId] = lodash.cloneDeep(data)

      return cloned
    })
  }
  let insertedTabs = []
  if (!isBioEnergy) {
    if (isAgriculture || isDtil || isDsf || isDni) {
      insertedTabs = [
        getAnalyzeTab("agricultureENK", t("profitability"), "agricultureENK"),
      ]
    } else if (isMaintenance) {
      insertedTabs = [
        getAnalyzeTab(
          "budgetWithoutInternalInterest",
          t("profitability"),
          "budgetWithoutInternalInterest"
        ),
      ]
    } else {
      insertedTabs = [getAnalyzeTab("budget", t("profitability"), "budget")]
    }
  }

  const options = [
    getAnalyzeTab("accounting", t("accounting"), "accounting"),
    getAnalyzeTab("company", t("about-company"), "company"),
    ...insertedTabs,
    getAnalyzeTab("forecast", t("forecast"), "forecast"),
    ...(!isMaintenance
      ? [
          getAnalyzeTab("esgSurveyNew", t("sustainability"), "esgSurveyNew"),
          getAnalyzeTab("financeplan", t("financePlan"), "financeplan"),
        ]
      : []),
    ...(isBioEnergy
      ? [
          {
            id: "internalinterest",
            title: t("internal-interest-calculation"),
            component: (
              <InternalInterestCalculation
                applicationContent={applicationContent}
                insightInternalInterestState={insightInternalInterestState}
                setInsightInternalInterestState={
                  setInsightInternalInterestState
                }
                internalInterestData={internalInterestData}
                setInternalInterestData={setInternalInterestData}
                onChange={(values) => setInsightInternalInterestState(values)}
                setErrors={setErrors}
                setProcessing={setProcessing}
                t={t}
              />
            ),
          },
        ]
      : []),
    {
      id: "specialconditions",
      title: t("specialTerms"),
      component: (
        <SpecialTermsTab
          deliveries={specialTerms}
          onChange={(values) => updateSpecialTerms(values)}
          t={t}
        />
      ),
    },
    {
      id: "comments",
      title: t("requested-documentation"),
      component: (
        <RequestedDocArchive
          documentations={
            props.flow?.data?.analysis?.updateCustomerInformation?.documentation
          }
          t={t}
        />
      ),
    },
    {
      id: "decisions",
      title: t("assessments"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentComments
          comments={flow?.data?.assessmentComments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]

  const handleTabChange = (tabId) => {
    const data = activeApp?.getCurrentState() || {}
    setInsightModuleState({ ...insightModuleState, ...data?.financePlan })
    setInsightInternalInterestState({
      ...insightInternalInterestState,
      ...data?.internalInterest,
    })
    setAnalysisTemplate(data?.analysisTemplate)

    setInsightEnkDataState({
      ...insightEnkDataState,
      ...data?.enkBudget,
    })
    setActiveTab(tabId)
  }

  const getFlowSchema = () => {
    delete schema.properties.financePlan
    schema.required = schema.required.filter((e) => e !== "financePlan")
    return schema
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab="accounting"
          loading={isLoading}
          options={options}
          onChange={(e) => handleTabChange(e)}
        />
      </BMAnalyzeContent>

      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          schema={getFlowSchema()}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={
                isErrored || isLoading || isProcessing || errors?.length > 0
              }
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
        {(isErrored || errors?.length > 0) && (
          <StyledNotice backgroundColor={Colors.FerrariLighter}>
            {errors?.map((e) => {
              return (
                <div>
                  <ErrorText key={e}>{t(`${e}`)}</ErrorText>
                </div>
              )
            })}
            {isErrored && (
              <div>
                <ErrorText>{t(`failed-to-load-task`)}</ErrorText>
              </div>
            )}
          </StyledNotice>
        )}
      </Context>
    </Layout>
  )
}

const ErrorText = styled.p`
  color: ${Colors.Ferrari};
  margin-top: 5px;
`
const StyledNotice = styled(Notice)`
  color: ${Colors.Ferrari};
  border-radius: 0;
  margin-top: 10px;
`
const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`

export default withTranslation()(AnalyzeApplication)
