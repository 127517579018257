import React, { useState } from "react"
import Layout, { Context } from "../../components/common/Layout"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

import Notice from "../../components/common/Notice"
import WarningInfoBox from "../../components/common/WarningInfoBox"
import { Colors, FontStyles } from "@flow/style"
import { TextArea } from "@flow/forms"
import ReactForm from "../../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import ErrorText from "../../components/common/ErrorText"

const FinalizingTask = (props) => {
  const { flow, t, task, schema } = props

  const warningText = props.warningText
  const label = t("addReason")
  const placeholderText = "" //t("comment-placeholder")

  const [formData, setFormData] = useState({
    comment: "",
  })
  const [error, setError] = useState(false)
  const [isProcessing, setProcessing] = useState(false)

  const handleTrigger = () => {
    setProcessing(false)
    if (!formData?.comment) {
      setError(true)
    } else {
      setProcessing(true)
      console.log("Complete")
      props.trigger(
        {
          ...formData,
        },
        () => {
          setProcessing(false)
        },
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  const rightColumnContext = {
    applicant: task.context.applicant,
    lossAssessmentReason: task.context.lossAssessmentReason,
    period: task.context.period,
  }

  const onFormChange = (values) => {
    setFormData(values)
  }

  return (
    <Layout forceHeight>
      <MainContainer>
        <Content>
          <WarningInfoBox padding="10px" text={warningText} />
        </Content>

        <>
          <TextWrapper>
            <LabelSpan>{label}</LabelSpan>
            <TextAreaBox
              help={t("internal-comment-help")}
              placeholder={placeholderText}
              value={formData?.comment}
              onChange={(e) => {
                setError(false)
                setFormData({ ...formData, comment: e.target.value })
              }}
              name="internal-comment"
              required
            />
          </TextWrapper>
          {error && <ErrorText error={t("required")} />}
        </>
      </MainContainer>
      <Context flow={flow} context={rightColumnContext}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
        >
          <></>
        </ReactForm>
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            onClick={handleTrigger}
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            {t("complete")}
          </PrimaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

const MainContainer = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
  padding: 2em;
`

const Content = styled(Notice)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.OrangeLighter};
`

const TextWrapper = styled.div`
  margin-top: 1.5em;
  max-width: 100%;
`
const TextAreaBox = styled(TextArea)`
  width: 100%;
  height: 150px;
`
const LabelSpan = styled.div`
  ${FontStyles.Normal};
  margin-bottom: 5px;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`
const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

export default withTranslation()(FinalizingTask)
